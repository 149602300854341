<template>
  <div>
    <div class="program-hero" v-if="achievementsList.length">
      <div @click="leftRotateArr" class="hero-icon hero-icon--up">
        <unicon name="angle-up" />
      </div>
      <div @click="rightRotateArr" class="hero-icon hero-icon--down">
        <unicon name="angle-down" />
      </div>
      <div class="program-hero__background">
        <transition name="hero-img-fade" mode="out-in">
          <img
            :src="achievements[achievements.length - 1]['imageid3after']"
            :key="achievements[achievements.length - 1]['imageid3after']"
            alt=""
            v-if="achievements.length"
          />
        </transition>
      </div>
      <div class="program-hero-container">
        <transition name="hero-card-fade" mode="out-in">
          <Achievement
            :achievement="achievementsList[achievements.length - 1]"
            :showThumb="true"
            :cardsSlideUp="cardsSlideUp"
            v-on:moreDetailClickHandler="achievementCardClickHandler"
            v-on:pauseInterval="pauseInterval"
            v-on:resumeInterval="resumeInterval"
          />
        </transition>
      </div>
    </div>
    <div class="program-list" v-if="achievementsList.length">
      <transition-group
        :name="cardsSlideUp ? 'flip-list-up' : 'flip-list-down'"
      >
        <Achievement
          v-for="achievement in achievementsList"
          :key="achievement['s.no.']"
          :achievement="achievement"
          :showThumb="true"
          v-on:achievementCardClickHandler="achievementCardClickHandler"
          v-on:pauseInterval="pauseInterval"
          v-on:resumeInterval="resumeInterval"
        />
      </transition-group>
    </div>
    <div class="program-list__no-data" v-else>
      <NoDataFound />
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      achievementsList: [],
      interval: "",
      cardsSlideUp: true
    };
  },
  computed: {
    achievements() {
      return this.$store.getters.achievements;
    }
  },
  created() {
    this.autoRotateArr();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  components: {
    Achievement: () => import("./achievement.vue")
  },
  watch: {
    achievements: {
      handler: function () {
        this.achievementsList = this.$store.getters.achievements;
      },
      immediate: true
    }
  },
  methods: {
    rightRotateArr() {
      // stop interval timer
      clearInterval(this.interval);
      // carousel slide up
      this.cardsSlideUp = false;
      // right rotate array
      let el = this.achievementsList.pop();
      setTimeout(() => this.achievementsList.unshift(el), 300);
      // restart the interval timer
      this.setIntervalForLeftRotation();
    },
    leftRotateArr() {
      // stop interval timer
      clearInterval(this.interval);
      this.cardsSlideUp = true;
      if (this.achievementsList.length !== 0) {
        let el = this.achievementsList.shift();
        setTimeout(() => this.achievementsList.push(el), 300);
      }
      // restart the interval timer
      this.setIntervalForLeftRotation();
    },
    autoRotateArr() {
      this.setIntervalForLeftRotation();
    },
    achievementCardClickHandler(id) {
      this.$router.push({
        name: "achievement-detail",
        params: { achievementId: id }
      });
    },
    setIntervalForLeftRotation() {
      this.interval = setInterval(() => {
        if (!this.isIntervalPaused) {
          this.leftRotateArr();
        }
      }, 10000);
    },
    pauseInterval() {
      this.isIntervalPaused = true;
    },
    resumeInterval() {
      this.isIntervalPaused = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
